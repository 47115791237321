@import "styles/_mixins.scss";

.error{
  height: calc(100vh - 12rem);

  > div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .wrapper{
    @include gold-border;
    display: flex;
    flex-direction: column;
    padding: var(--spacing6);

    border-width: 1px;


    .row{
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing6);

      .status{
        margin: 0 var(--spacing4) 0 0;
        padding-right: var(--spacing4);

        color: var(--yellow);

        font-weight: 700;
        font-size: var(--fontSize5);
        font-family: var(--secondaryFont);

        border-right: 1px solid var(--yellow);
      }

      .message{
        color: var(--yellow);
        font-family: var(--secondaryFont);
      }
    }

    .button{
      width: 100%;
      margin-bottom: var(--spacing5);
    }
  }
}
